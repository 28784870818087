<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12" cols-lg="6">
          <b-form-group
            id="fieldset-start-date"
            label="Start Date"
            label-cols="3"
            label-cols-lg="4"
            label-for="start-datepicker"
          >
            <b-form-datepicker
              id="start-datepicker"
              v-model="dateStart"
              class="mb-2"
              @input="gotoDate(dateStart)"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <FullCalendar :ref="calendarRef" :options="config" @view-render="viewRendered" />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'

// const FULLCALENDAR_SCHEDULER_LICENSE = 'CC-Attribution-NonCommercial-NoDerivatives'

export default {
  components: {
    FullCalendar,
  },
  name: 'Calendar',
  props: {
    calendarRef: {
      type: String,
      default: 'calendar',
    },
    initialView: {
      type: String,
      default: 'listWeek',
    },
    events: {
      type: Array,
      default: () => [],
    },
    eventPk: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      dateStart: null,
      calendarApi: null,
      calendarView: null,
    }
  },
  computed: {
    config() {
      return {
        ...this.configOptions,
        ...this.eventHandlers,
      }
    },
    configOptions() {
      return {
        themeSystem: 'bootstrap',
        plugins: [
          dayGridPlugin,
          listPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          bootstrapPlugin,
        ],
        events: this.events,
        // schedulerLicenseKey: FULLCALENDAR_SCHEDULER_LICENSE,
        height: 'auto',
        // titleFormat: {
        //   month: 'short',
        //   year: 'numeric',
        // },
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prevYear,prev,next,nextYear today',
        },
        footerToolbar: {
          left: '',
          center: '',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        },
        buttonText: {
          list: 'week list',
        },
        initialView: this.calendarView,
        slotDuration: '00:15:00',
        eventOverlap: true,
        // eventRender: function (event, element, view) {
        //   let title = element.find('.fc-title, .fc-list-item-title')
        //   if (view.name === 'listDay') {
        //     let titleHtml = title.text().replace(/<br \/>/gi, ' ')
        //     title.html(titleHtml)
        //   } else {
        //     title.html(title.text())
        //   }
        // },
        contentHeight: window.innerHeight - 300,
        select(start, end, jsEvent, view) {
          console.log('FullCalendar::select()', start, end, jsEvent, view)
        },
      }
    },
    eventHandlers() {
      return {
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        select: this.onDateSelect,
        datesSet: this.onDatesSet,
      }
    },
  },
  methods: {
    viewRendered(view, element) {
      console.log('viewRendered', view, element)
    },
    gotoDate(start) {
      console.log('dateStart', start)
      this.calendarApi.gotoDate(start)
    },
    changeView(viewName, dateOrRange) {
      this.calendarApi.changeView(viewName, dateOrRange)
    },
    // onDateClick (payload) {},
    onEventClick({ event }) {
      this.$emit('event-clicked', event[this.eventPk])
    },
    // onEventDrop ({ event }) {},
    // onDateSelect (payload) {},
    onDatesSet(info) {
      // this.$log('onDatesSet', info)
      this.$emit('dates-set', info)
    },
  },
  created() {
    this.calendarView = this.initialView
  },
  mounted() {
    this.calendarApi = this.$refs[this.calendarRef].getApi()
  },
}
</script>

<style>
.fc-ltr .fc-list-item-marker {
  padding-right: 14px;
}

.cal_event_legend {
  width: auto;
  display: inline-block;
  padding: 2px;
}
.cal_event_confirmed {
  position: absolute;
  top: 2px;
  right: 5px;
}
.cal_event_new,
.cal_event_updated,
.cal_event_blocked,
.cal_event_in_progress {
  background-color: #0099ff;
  border-color: #0099ff;
}
.cal_event_canceled {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.cal_event_pending {
  color: #000;
  background-color: yellow;
  border-color: #ff66cc;
}
.cal_event_complete {
  color: #000;
  background-color: #ff66cc;
  border-color: #ff66cc;
}
.cal_event_blocked {
  background-color: #eee;
}
/*.cal_event_consultation { background-image: url(/assets/images/calendar-event-bg.png); }*/

.cal_event_alt_franchise {
  background-color: #999 !important;
  color: #fff !important;
}

.calEntry {
  color: #000;
  background-color: #00ff00;
}
</style>
